// resourceStyle.js

// material ui class creator
import { makeStyles } from '@material-ui/core/styles'

// icons
import MarkunreadIcon from '@material-ui/icons/Markunread'
import DraftsIcon from '@material-ui/icons/Drafts'
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded'
import FaceIcon from '@material-ui/icons/Face'
import AndroidIcon from '@material-ui/icons/Android'

export const useStyles = makeStyles({
  inlineBlock: { display: 'inline-flex', marginInlineEnd: '2rem' },
  inlineBlockRight: { display: 'inline-flex', marginInlineEnd: '2rem', float: 'right' },
})

export const showStars = (record) => {
  if (record.password === undefined) {
    return '****************'
  } else {
    return `************${record.password.slice(-5)}`
  }
}

export const showReaded = (record) => {
  if (record.readed === true) {
    return <DraftsIcon />
  } else {
    return <MarkunreadIcon />
  }
}

export const showImportant = (record) => {
  if (record.important === true) {
    return <ErrorRoundedIcon />
  } else {
    return <i></i>
  }
}

export const showOrigin = (record) => {
  if (record.origin === 'BOT') {
    return <AndroidIcon />
  } else if (record.origin === 'USER') {
    return <FaceIcon />
  }
}
