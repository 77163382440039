// customRoutes.js

//import { Route } from 'react-router-dom'

// the cursom routes
//import Name from './Name.js'

const customRoutes = [
  //<Route exact path="/name" component={Name} />,
]

export default customRoutes
