// AppBar.js

import React, { forwardRef } from 'react'

// react admin
import { AppBar, usePermissions, useLogout } from 'react-admin'

import { withStyles } from '@material-ui/core/styles'

// material components
import Chip from '@material-ui/core/Chip'
import Tooltip from '@material-ui/core/Tooltip'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
import CircularProgress from '@material-ui/core/CircularProgress'

// material icons
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'
import PersonIcon from '@material-ui/icons/Person'
import HowToRegIcon from '@material-ui/icons/HowToReg'
import RedditIcon from '@material-ui/icons/Reddit'

// custom appbar components
//import NotificationPill from '../Components/NotificationPill.js'

// make the custom styles for the components (colors mainly)
const CircularProgressColor = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.contrastText
  },
}))(CircularProgress)
const PersonIconColor = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.contrastText
  },
}))(PersonIcon)
const HowToRegIconColor = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.contrastText
  },
}))(HowToRegIcon)
const RedditIconColor = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.contrastText
  },
}))(RedditIcon)

// custom logout button without that empty menu
const LogoutButton = forwardRef((props, ref) => {
  const logout = useLogout()
  const handleClick = () => logout()
  return (
    <Tooltip title="Cerrar sesión">
      <IconButton aria-label="cerrar sesión" style={{color: 'inherit'}} onClick={handleClick} ref={ref}>
          <PowerSettingsNewIcon />
      </IconButton>
    </Tooltip>
  )
})

// custom logout button without that empty menu
const UserMenu = ((props) => {
  const permissions = usePermissions()
  return (
    <div>
      {/*<NotificationPill />*/}
      <Hidden mdDown={true}>
        <Tooltip title={localStorage.getItem('username')} display={{ xs: 'none', sm: 'block' }}>
          <Chip label={permissions.loading ? <CircularProgressColor/> : ["ADMIN"].includes(permissions.permissions) ? <RedditIconColor /> : ["MANAGER"].includes(permissions.permissions) ? <HowToRegIconColor /> : <PersonIconColor /> } variant="outlined" style={{marginLeft: '5px', marginRight: '5px'}} />
        </Tooltip>
      </Hidden>
      <LogoutButton />
    </div>
  )
})

// logo for navigation
const CustomAppBar = (props) => {
  return (
    <AppBar userMenu={<UserMenu/>} {...props}>
      <div style={{marginLeft: 'auto', marginRight: 'auto'}}>
        <a href="/">
          <img
          style={{height: '28px'}}
          src={`/${process.env.REACT_APP_LOGO}`}
          alt={process.env.REACT_APP_SITE_NAME} />
        </a>
      </div>
    </AppBar>
  )
}

export default CustomAppBar
