// transcriptions.js

import React from 'react'

// react admin
import { List, FunctionField, ReferenceField, ReferenceInput, Pagination, Datagrid, TextField, NumberField, DateField, Filter, Create, TopToolbar, CreateButton, DeleteButton, SelectInput, TextInput, Edit, SimpleForm, Show, SimpleShowLayout } from 'react-admin' //BooleanField, BooleanInput, SelectField, 

// the material classes for the resources
import { showOrigin } from '../themes/resourceStyle.js' //showReaded, showImportant, useStyles, 

/*
create table transcriptions(
id serial not null primary key,
origin messageoriginchoices not null,
transcription varchar(1024) not null,
transcriptioncall integer references calls(id) on delete cascade not null,
created timestamp not null default now()
);
*/

const resource = "transcriptions"
const resourceName = "transcripción"
const resourceNamePlural = "transcripcionnes"
const masculine = false //${masculine ? "un" : "una"}
const mainField = "transcription"

const ListTitle = ({ record }) => {
  return <span>{`${resourceNamePlural.charAt(0).toUpperCase()}${resourceNamePlural.slice(1)}`}</span>
}

const ListFilters = (props) => (
  <Filter {...props}>
    <TextInput label={`Buscar texto...`} source={`${mainField}`} alwaysOn />
    <SelectInput label={`Filtrar orígen...`} source="origin" alwaysOn choices={[
      { id: 'USER', name: 'Usuario' },
      { id: 'BOT', name: 'Bot' },
    ]} />
  </Filter>
)

const ListPagination = (props) => {
  return <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
}

const ListActions = (props) => (
  <TopToolbar>
    <DeleteButton basePath={resource} />
    <CreateButton basePath={resource} />
  </TopToolbar>
)

export const TranscriptionList = (props) => {
  return (
  <List title={<ListTitle />} filters={<ListFilters />} pagination={<ListPagination />} perPage={25} actions={<ListActions />} {...props}>
    <Datagrid rowClick="edit">
      <NumberField source="id" label="ID" textAlign="left" />
      <FunctionField source="origin" label="Orígen" render={showOrigin} textAlign="left" />
      <TextField source="transcription" label="Transcripción" textAlign="left" />
      <ReferenceField source="transcriptioncall" label="Llamada" reference="calls">
        <TextField source="sid" />
      </ReferenceField>
      <DateField source="created" label="Creado" textAlign="left" locales="es" showTime={true} options={{year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', timeZone: 'Europe/Madrid'}} />
    </Datagrid>
  </List>
)}

const ShowTitle = ({ record }) => {
  return <span>{`${resourceName.charAt(0).toUpperCase()}${resourceName.slice(1)} ${record[mainField]}`}</span>
}

export const TranscriptionShow = (props) => {
  return (
  <Show title={<ShowTitle />} {...props}>
    <SimpleShowLayout>
      <NumberField source="id" label="ID" textAlign="left" />
      <FunctionField source="origin" label="Orígen" render={showOrigin} textAlign="left" />
      <TextField source="transcription" label="Transcripción" textAlign="left" />
      <ReferenceField source="transcriptioncall" label="Llamada" reference="calls">
        <TextField source="sid" />
      </ReferenceField>
      <DateField source="created" label="Creado" textAlign="left" locales="es" showTime={true} options={{year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', timeZone: 'Europe/Madrid'}} />
    </SimpleShowLayout>
  </Show>
)}

const EditTitle = ({ record }) => {
  return <span>Editar {`${resourceName.charAt(0).toUpperCase()}${resourceName.slice(1)} ${record[mainField]}`}</span>
}

export const TranscriptionEdit = (props) => {
  //const classes = useStyles()
  return (
  <Edit title={<EditTitle />} {...props}>
    <SimpleForm>
      <NumberField source="id" label="ID" textAlign="left" />
      <FunctionField source="origin" label="Orígen" render={showOrigin} textAlign="left" />
      <TextField source="transcription" label="Transcripción" textAlign="left" />
      <ReferenceField source="transcriptioncall" label="Llamada" reference="calls">
        <TextField source="sid" />
      </ReferenceField>
      <DateField source="created" label="Creado" textAlign="left" locales="es" showTime={true} options={{year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', timeZone: 'Europe/Madrid'}} />
    </SimpleForm>
  </Edit>
)}

const CreateTitle = ({ record }) => {
  return <span>Crear {masculine ? "nuevo" : "nueva"} {`${resourceName.charAt(0).toUpperCase()}${resourceName.slice(1)}`}</span>
}

const validateTranscriptionCreation = (values) => {
  const errors = {}
  if (!values.origin) {
    errors.origin = 'Es necesario indicar un orígen'
  }
  if (!values.transcription) {
    errors.transcription = 'Es necesario introducir una transcripción'
  }
  if (!values.transcriptioncall) {
    errors.transcriptioncall = 'Es necesario seleccionar una llamada'
  }
  return errors
}

export const TranscriptionCreate = (props) => {
  return (
    <Create title={<CreateTitle />} {...props}>
      <SimpleForm validate={validateTranscriptionCreation}>
        <ReferenceInput source="transcriptioncall" label="Llamada" reference="calls" sort={{ field: 'sid', order: 'ASC' }} perPage={100000000} fullWidth={true}>
          <SelectInput optionText="sid" />
        </ReferenceInput>
        <SelectInput source="origin" label="Orígen" fullWidth={true} choices={[
          { id: 'USER', name: 'Usuario' },
          { id: 'BOT', name: 'Bot' },
        ]} />
        <TextInput source="transcription" label="Transcripción" helperText="El contenido de la transcripción" fullWidth={true} textAlign="left" />
      </SimpleForm>
    </Create>
  )
}