// users.js

import React, { useState, useEffect }  from 'react'

import { useHistory } from "react-router-dom"

// react admin
import { useQueryWithStore, Button, List, SelectField, Pagination, Datagrid, TextField, NumberField, DateField, Filter, Create, TopToolbar, CreateButton, DeleteButton, SelectInput, TextInput, Edit, SimpleForm, Show, SimpleShowLayout, ReferenceManyField, ReferenceField, FunctionField } from 'react-admin'

// the material classes for the resources
import { useStyles, showOrigin } from '../themes/resourceStyle.js'

import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'

/*
create type callstatuschoices as enum ('queued','initiated','ringing','in-progress','completed','busy','no-answer','canceled','failed');

create table calls(
id serial not null primary key,
sid varchar(255) not null unique,
phone varchar(255) not null,
status callstatuschoices not null default 'initiated',
created timestamp not null default now(),
updated timestamp
);
*/

const resource = "calls"
const resourceName = "llamada"
const resourceNamePlural = "llamadas"
const masculine = false
const mainField = "sid"

const ListTitle = ({ record }) => {
  return <span>{`${resourceNamePlural.charAt(0).toUpperCase()}${resourceNamePlural.slice(1)}`}</span>
}

const ListFilters = (props) => (
  <Filter {...props}>
      <TextInput label={`Buscar por SID...`} source={`${mainField}`} alwaysOn />
      <TextInput label={`Buscar por teléfono...`} source="phone" alwaysOn />
      <SelectInput fullWidth={true} label={`Buscar estado`} source="status" alwaysOn choices={[
        { id: 'QUEUED', name: 'Encolada' },
        { id: 'INITIATED', name: 'Iniciada' },
        { id: 'RINGING', name: 'Llamando' },
        { id: 'IN_PROGRESS', name: 'En curso' },
        { id: 'COMPLETED', name: 'Completada' },
        { id: 'BUSY', name: 'Línea ocupada' },
        { id: 'NO_ANSWER', name: 'Sin respuesta' },
        { id: 'CANCELED', name: 'Cancelada' },
        { id: 'FAILED', name: 'Fallida' },
      ]} />
  </Filter>
)

const ListPagination = (props) => {
  return <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
}

const ListActions = (props) => (
  <TopToolbar>
    <DeleteButton basePath={resource} />
    <CreateButton basePath={resource} />
  </TopToolbar>
)

export const CallList = (props) => {
  return (
  <List title={<ListTitle />} filters={<ListFilters />} pagination={<ListPagination />} actions={<ListActions />} sort={{ field: 'id', order: 'DESC' }} {...props}>
    <Datagrid rowClick="edit">
      <NumberField source="id" label="ID" textAlign="left" />
      {/*<TextField source="sid" label="SID" textAlign="left" />*/}
      <TextField source="phone" label="Telefono" textAlign="left" />
      <SelectField source="status" label="Estado" choices={[
        { id: 'QUEUED', name: 'Encolada' },
        { id: 'INITIATED', name: 'Iniciada' },
        { id: 'RINGING', name: 'Llamando' },
        { id: 'IN_PROGRESS', name: 'En curso' },
        { id: 'COMPLETED', name: 'Completada' },
        { id: 'BUSY', name: 'Línea ocupada' },
        { id: 'NO_ANSWER', name: 'Sin respuesta' },
        { id: 'CANCELED', name: 'Cancelada' },
        { id: 'FAILED', name: 'Fallida' },
       ]} />
      <DateField source="created" label="Creado" textAlign="left" locales="es" showTime={true} options={{year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', timeZone: 'Europe/Madrid'}} />
      {/*<DateField source="updated" label="Actualizado" textAlign="left" emptyText="Nunca" locales="es" showTime={true} options={{year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', timeZone: 'Europe/Madrid'}} />*/}
    </Datagrid>
  </List>
)}

const ShowTitle = ({ record }) => {
  return <span>{`${resourceName.charAt(0).toUpperCase()}${resourceName.slice(1)} ${record[mainField]}`}</span>
}

export const CallShow = (props) => {
  return (
  <Show title={<ShowTitle />} {...props}>
    <SimpleShowLayout>
      <NumberField source="id" label="ID" textAlign="left" />
      {/*<TextField source="sid" label="SID" textAlign="left" />*/}
      <TextField source="phone" label="Telefono" textAlign="left" />
      <SelectField source="status" label="Estado" choices={[
        { id: 'QUEUED', name: 'Encolada' },
        { id: 'INITIATED', name: 'Iniciada' },
        { id: 'RINGING', name: 'Llamando' },
        { id: 'IN_PROGRESS', name: 'En curso' },
        { id: 'COMPLETED', name: 'Completada' },
        { id: 'BUSY', name: 'Línea ocupada' },
        { id: 'NO_ANSWER', name: 'Sin respuesta' },
        { id: 'CANCELED', name: 'Cancelada' },
        { id: 'FAILED', name: 'Fallida' },
       ]} />
      <DateField source="created" label="Creado" textAlign="left" locales="es" showTime={true} options={{year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', timeZone: 'Europe/Madrid'}} />
      {/*<DateField source="updated" label="Actualizado" textAlign="left" emptyText="Nunca" locales="es" showTime={true} options={{year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', timeZone: 'Europe/Madrid'}} />*/} 
      </SimpleShowLayout>
  </Show>
)}

const EditTitle = ({ record }) => {
  return <span>Editar {`${resourceName.charAt(0).toUpperCase()}${resourceName.slice(1)} ${record[mainField]}`}</span>
}

export const CallEdit = ({match, ...props}) => {
  const classes = useStyles()
  const history = useHistory()
  //loaded, error, 
  const { data } = useQueryWithStore({
    type: 'getList',
    resource: resource,
    payload: { pagination: { page: 1, perPage: 999999 }, sort: { field: 'id', order: 'ASC' } }
  })
  const [next, setNext] = useState(null)
  const [prev, setPrev] = useState(null)
  useEffect(() => {
    if (data !== undefined) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].id === Number(match.params.id)) {
          if (i > 0) {
            setPrev(data[i-1].id)
          }
          if (i < data.length - 1) {
            setNext(data[i+1].id)
          }
        }
      }
    }
  }, [match.params.id, data])
  //console.log(next)
  //console.log(prev)
  return (
  <Edit title={<EditTitle />} {...props}>
    <SimpleForm>
      {prev && <Button children={<ChevronLeft />} color="primary" style={{width: 'auto'}} onClick={() => history.push(`/${resource}/${prev}`)}></Button>}
      {next && <Button children={<ChevronRight />} color="primary" style={{width: 'auto'}} onClick={() => history.push(`/${resource}/${next}`)}></Button>}
      <NumberField source="id" label="ID" textAlign="left" formClassName={classes.inlineBlock} />
      <TextInput source="sid" label="SID" textAlign="left" fullWidth={true} />
      <TextInput source="phone" label="Telefono" textAlign="left" fullWidth={true} />
       <SelectInput source="status" label="Estado" helperText="Estado de la llamada" fullWidth={true} choices={[
        { id: 'QUEUED', name: 'Encolada' },
        { id: 'INITIATED', name: 'Iniciada' },
        { id: 'RINGING', name: 'Llamando' },
        { id: 'IN_PROGRESS', name: 'En curso' },
        { id: 'COMPLETED', name: 'Completada' },
        { id: 'BUSY', name: 'Línea ocupada' },
        { id: 'NO_ANSWER', name: 'Sin respuesta' },
        { id: 'CANCELED', name: 'Cancelada' },
        { id: 'FAILED', name: 'Fallida' },
       ]}/>
       <ReferenceManyField label="Transcripciones de la llamada" reference="transcriptions" target="calls" sort={{field: 'id', order: 'ASC'}} perPage={1000000} fullWidth={true} filter={{transcriptioncall: Number.parseInt(props.id)}}>
          <Datagrid>
            <ReferenceField source="id" label="ID" reference="transcriptions">
              <TextField source="id" />
            </ReferenceField>
            <FunctionField source="origin" label="Orígen" render={showOrigin} textAlign="left" />
            <TextField multiline="true" source="transcription" label="Transcripción" />
            <DateField source="created" label="Creado" textAlign="left" locales="es" showTime={true} options={{year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', timeZone: 'Europe/Madrid'}} />
          </Datagrid>
        </ReferenceManyField>
    </SimpleForm>
  </Edit>
)}

const CreateTitle = ({ record }) => {
  return <span>Crear {masculine ? "nuevo" : "nueva"} {`${resourceName.charAt(0).toUpperCase()}${resourceName.slice(1)}`}</span>
}

const validateCallCreation = (values) => {
  const errors = {}
  if (!values.sid) {
    errors.sid = 'Es necesario introducir una SID'
  }
  if (!values.phone) {
    errors.phone = 'Es necesario introducir un número de teléfono'
  }
  return errors
}

export const CallCreate = (props) => {
  return (
    <Create title={<CreateTitle />} {...props}>
      <SimpleForm validate={validateCallCreation}>
        <TextInput source="sid" label="SID" textAlign="left" fullWidth={true} />
        <TextInput source="phone" label="Telefono" textAlign="left" fullWidth={true} />
        <SelectInput source="status" label="Estado" helperText="Estado de la llamada" fullWidth={true} choices={[
          { id: 'QUEUED', name: 'Encolada' },
          { id: 'INITIATED', name: 'Iniciada' },
          { id: 'RINGING', name: 'Llamando' },
          { id: 'IN_PROGRESS', name: 'En curso' },
          { id: 'COMPLETED', name: 'Completada' },
          { id: 'BUSY', name: 'Línea ocupada' },
          { id: 'NO_ANSWER', name: 'Sin respuesta' },
          { id: 'CANCELED', name: 'Cancelada' },
          { id: 'FAILED', name: 'Fallida' },
         ]}/>
      </SimpleForm>
    </Create>
  )
}