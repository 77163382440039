// App.js

import React, { useEffect, useState } from 'react'

// react admin
import { Admin, Resource } from 'react-admin'

// data providers
import { useApolloClient } from '@apollo/react-hooks'
// original dataprovider version from bowlingx
import pgDataProvider from 'ra-postgraphile'

// auth providers
import authProvider from './authProviders/authProvider.js'

// resources
//import { UserList, UserShow, UserEdit, UserCreate } from './resources/users.js'
//import { NotificationList, NotificationShow, NotificationEdit, NotificationCreate } from './resources/notifications.js'
import { CallList, CallShow, CallEdit, CallCreate } from './resources/calls.js'
import { TranscriptionList, TranscriptionShow, TranscriptionEdit, TranscriptionCreate } from './resources/transcriptions.js'


// icons
//import PersonIcon from '@material-ui/icons/Person'
import CallIcon from '@material-ui/icons/Call';
//import AnnouncementIcon from '@material-ui/icons/Announcement'
import CommentIcon from '@material-ui/icons/Comment'


// main custom theme
import mainTheme from './themes/mainTheme.js'

// custom login page background
import Login from './Auth/Login.js'

// custom sidebar and top navigation bar
import Layout from './Layouts/Layout.js'

// main dashboard
import Dashboard from './Dashboards/Dashboard.js'

// app custom routes
import customRoutes from './customRoutes/index.js'

// translations
// https://github.com/BlackBoxVision/ra-language-spanish
import spanishMessages from '@blackbox-vision/ra-language-spanish'
// https://marmelab.com/react-admin/Translation.html
import polyglotI18nProvider from 'ra-i18n-polyglot'
// maybe change for https://github.com/marmelab/react-admin/blob/master/packages/ra-language-english/src/index.ts and translate?
const i18nProvider = polyglotI18nProvider(() => spanishMessages, 'es', {allowMissing: true})

// for removing # in routes
const createHistory = require('history').createBrowserHistory
const history = createHistory()

const App = () => {

  // get apollo client from provider
  const client = useApolloClient()
  // save the dataProvider only once
  const [dataProvider, setDataProvider] = useState(null)
  const [init, setInit] = useState(false)

  useEffect(() => {
    ;(async () => {
      if (!init) {
        setInit(true)
        const dataProvider = await pgDataProvider(client, /*{
          typeMap: {
            Usertypechoices: {
              expand: true,
            },
          },
        }*/)
        // fix for apollo-client v3 in TS
        //const dataProvider = await pgDataProvider(client) // config at second arg
        setDataProvider(() => dataProvider)
      }
    })()
  }, [client, init])

  return (
    dataProvider && (
      <Admin 
        disableTelemetry 
        style={{flexGrow: 1}} 
        customRoutes={customRoutes} 
        history={history} 
        title={`${process.env.REACT_APP_SITE_NAME} - Administrador `} 
        i18nProvider={i18nProvider} 
        loginPage={Login} 
        layout={Layout} 
        dashboard={Dashboard} 
        theme={mainTheme} 
        authProvider={authProvider} 
        dataProvider={dataProvider}
      >
        {permissions => [
          ["ADMIN","MANAGER"].includes(permissions)
          ? <Resource name="calls" options={{ label: 'Llamadas' }} list={CallList} show={CallShow} create={CallCreate} edit={CallEdit} icon={CallIcon} />
          : <Resource name="calls" options={{ label: 'Llamadas' }} list={null} show={null} create={null} edit={null} icon={CallIcon} />,
          ["PATATA"].includes(permissions)
          ? <Resource name="transcriptions" options={{ label: 'Transcripciones' }} list={TranscriptionList} show={TranscriptionShow} edit={TranscriptionEdit} create={TranscriptionCreate} icon={CommentIcon} />
          : <Resource name="transcriptions" options={{ label: 'Transcripciones' }} list={null} show={null} edit={null} create={null} icon={CommentIcon}/>,
        ]}
      </Admin>
    )
  )
}

export default App
