// Layout.js

import React from 'react'

// react admin
import { Layout } from 'react-admin'

// custom components of the layout
import AppBar from './AppBar.js'
import Sidebar from './Sidebar.js'
import Menu from './Menu.js'

const CustomLayout = (props) => {
  return (
    <Layout
      style={{backgroundImage: `url(/${process.env.REACT_APP_BACKGROUND})`, flexGrow: 1, marginLeft: '8px', marginRight: '8px'}}
      appBar={AppBar}
      sidebar={Sidebar}
      menu={Menu}
      {...props} />
  )
}

export default CustomLayout
