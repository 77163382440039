import React from 'react'
import ReactDOM from 'react-dom'
//import './theme/index.css'
import reportWebVitals from './reportWebVitals'

// pick a datetime utils
import MomentUtils from '@date-io/moment'
import moment from 'moment'
import 'moment/locale/es'
// datetime picker init
import { MuiPickersUtilsProvider } from '@material-ui/pickers'

// graphql client provider component
import ClientProvider from './dataProviders/ClientProvider.js'

const locale = moment.locale('es') // it is required to select default locale manually

ReactDOM.render(
  <React.StrictMode>
    <MuiPickersUtilsProvider utils={MomentUtils} locale={locale}>
      <ClientProvider />
    </MuiPickersUtilsProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
