// ClientProvider.js
import React, { useEffect, useState } from 'react'
// the connector client
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/react-hooks'
// the admin app
import App from '../App.js'

const ClientProvider = () => {

  // we do this to prevent initializing client recursively
  const [client, setClient] =  useState(null)

  // init client on mount
  useEffect(() => {
    try {
      const tmpClient = new ApolloClient({
        uri: `https://api.${process.env.REACT_APP_BASE_URL}/api/graphql`,
        cache: new InMemoryCache()
      })
      setClient(() => tmpClient)
    } catch (err) {
      console.error(err)
    }
  }, [])

  // if we have the client init return admin
  return (
    client && (
      <ApolloProvider client={client}>
        <App/>
      </ApolloProvider>
    )
  )
}

export default ClientProvider
