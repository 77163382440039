// Sidebar.js

import React, { useEffect } from 'react'

// react admin
import { Sidebar, setSidebarVisibility } from 'react-admin'

// redux store dispatch
import { useDispatch } from 'react-redux'

const CustomSidebar = (props) =>  {
  // next lines close the disebar by default
  // https://stackoverflow.com/questions/56874431/how-to-start-with-left-menu-collapsed/62222154#62222154
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setSidebarVisibility(false))
  }, [dispatch])
  return (
    <Sidebar {...props} />
  )
}

export default CustomSidebar
