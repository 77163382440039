// authProvider.js

const dev = true

const authProvider = {
  // called when the user attempts to log in
  login: async ({ username, password, token }) => {
    return fetch(`https://api.${process.env.REACT_APP_BASE_URL}/authProvider/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }, // ?
      body: JSON.stringify({
        username: username,
        password: password,
        token: token
      })
    })
    .then(async (res) => {
      dev && console.log(`login response : http ${res.status}`)
      if (res.ok) {
        const data = await res.json()
        localStorage.setItem('jwt', data.token)
        localStorage.setItem('perms', data.type)
        localStorage.setItem('uuid', data.uuid)
        localStorage.setItem('id', data.id)
        localStorage.setItem('username', username)
        dev && console.log('Login data saved in localStorage')
      } else {
        dev && console.log('Login was not correct, rejecting...')
        return Promise.reject({ message: 'Los datos proporcionados no son correctos. Por favor, vuelve a intentarlo' })
      }
      dev && console.log('All ok in login, resolving...')
      return Promise.resolve()
    })
    .catch(err => {
      dev && console.error(err)
      console.error('Error making login request')
      return Promise.reject({ message: 'No se ha podido realizar la petición. Por favor, vuelve a intentarlo' })
    })
  },
  // called when the user clicks on the logout button
  logout: () => {
    try {
      localStorage.removeItem('jwt')
      localStorage.removeItem('perms')
      localStorage.removeItem('uuid')
      localStorage.removeItem('id')
      localStorage.removeItem('username')
    } catch (err) {
      console.warn('Could not delete jwt from localStorage')
    }
    return Promise.resolve()
  },
  // called when the API returns an error
  checkError: ({ status }) => {
    if ([401,403].includes(status)) {
      try {
        localStorage.removeItem('jwt')
        localStorage.removeItem('perms')
        localStorage.removeItem('uuid')
        localStorage.removeItem('id')
        localStorage.removeItem('username')
      } catch (err) {
        console.warn('Could not delete jwt from localStorage')
      }
      return Promise.reject({ redirectTo: '/login', message: true } )
    } else {
      return Promise.resolve()
    }
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    dev && console.log(`checkAuth : ${localStorage.getItem('jwt')}`)
    // if we do not have a token reject directly
    if (localStorage.getItem('jwt') === null) {
      return Promise.reject({ redirectTo: '/', message: 'Por favor, vuelve a iniciar sesión para continuar' })
    }
    // will decode the jwt and check the expirity date
    return fetch(`https://api.${process.env.REACT_APP_BASE_URL}/authProvider/check-permissions/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }, // ?
      body: JSON.stringify({
        jwt: localStorage.getItem('jwt')
      })
    })
    .then(async (res) => {
      dev && console.log(`checkAuth response : http ${res.status}`)
      if (res.ok) {
        const data = await res.json()
        // save the data in localStorage for cached access
        localStorage.setItem('perms', data.decoded.type)
        localStorage.setItem('uuid', data.decoded.uuid)
        localStorage.setItem('id', data.decoded.id)
        localStorage.setItem('username', data.decoded.username)
        // check expirity date
        let expires = new Date(0)
        expires.setUTCSeconds(data.decoded.exp)
        const now = new Date()
        if (now <= expires) {
          Promise.resolve()
        } else {
          console.log('JWT expired')
          Promise.reject({ redirectTo: '/', message: 'Por favor, vuelve a iniciar sesión para continuar' })
        }
      } else {
        return Promise.reject({ redirectTo: '/', message: 'Por favor, vuelve a iniciar sesión para continuar' })
      }
    })
    .catch(err => {
      if (err instanceof TypeError) {
        dev && console.log('Could not fetch jwt expirity date, resolving...')
        return Promise.resolve()
      } else {
        dev && console.error(err)
        console.error('Error fetching jwt expirity date')
        return Promise.reject({ redirectTo: '/', message: 'Por favor, vuelve a iniciar sesión para continuar' })
      }
    })
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => {
    //Arreglo inicio de sesion
    if (window.location.pathname === '/login')
      return Promise.resolve()
    dev && console.log(`getPermissions : ${localStorage.getItem('jwt')}`)
    // if we do not have a token reject directly
    if (localStorage.getItem('jwt') === null) {
      return Promise.reject()
    }
    // will decode jwt as all permissions are there (server side checks are also implemented too)
    return fetch(`https://api.${process.env.REACT_APP_BASE_URL}/authProvider/check-permissions/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }, // ?
      body: JSON.stringify({
        jwt: localStorage.getItem('jwt')
      })
    })
    .then(async (res) => {
      dev && console.log(`getPermissions response : http ${res.status}`)
      if (res.ok) {
        const data = await res.json()
        //console.log('Permissions')
        //console.log(data)
        // save the data in localStorage for cached access
        localStorage.setItem('perms', data.decoded.type)
        localStorage.setItem('uuid', data.decoded.uuid)
        localStorage.setItem('id', data.decoded.id)
        localStorage.setItem('username', data.decoded.username)
        return Promise.resolve(data.decoded.type)
      } else {
        return Promise.reject()
      }
    })
    .catch(err => {
      if (err instanceof TypeError) {
        dev && console.log('Could not fetch jwt expirity date, resolving...')
        return Promise.resolve()
      } else {
        dev && console.error(err)
        console.error('Error fetching jwt permissions')
        return Promise.reject()
      }
    })
  },
  getIdentity: () => {
    //Arreglo inicio de sesion
    if (window.location.pathname === '/login')
      return Promise.resolve()
    dev && console.log(`getIdentity : ${localStorage.getItem('jwt')}`)
    // if we do not have a token reject directly
    if (localStorage.getItem('jwt') === null) {
      return Promise.reject()
    }
    // will decode jwt as all permissions are there (server side checks are also implemented too)
    return fetch(`https://api.${process.env.REACT_APP_BASE_URL}/authProvider/check-permissions/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }, // ?
      body: JSON.stringify({
        jwt: localStorage.getItem('jwt')
      })
    })
    .then(async (res) => {
      dev && console.log(`getIdentity response : http ${res.status}`)
      if (res.ok) {
        const data = await res.json()
        //console.log('Permissions')
        //console.log(data)
        // save the data in localStorage for cached access
        localStorage.setItem('perms', data.decoded.type)
        localStorage.setItem('uuid', data.decoded.uuid)
        localStorage.setItem('id', data.decoded.id)
        localStorage.setItem('username', data.decoded.username)
        return Promise.resolve({id: data.decoded.id, fullName: data.decoded.username}) //avatar?: string (optional)
      } else {
        return Promise.reject()
      }
    })
    .catch(err => {
      if (err instanceof TypeError) {
        dev && console.log('Could not fetch jwt identity, resolving...')
        return Promise.resolve()
      } else {
        dev && console.error(err)
        console.error('Error fetching jwt identity')
        return Promise.reject()
      }
    })
  }
}

export default authProvider