// mainTheme.js

import { createMuiTheme } from '@material-ui/core/styles'

export default createMuiTheme({
  palette: {
    // see https://material-ui.com/customization/color/#official-color-tool
    // color tool https://material.io/resources/color/#!/
    primary: {
      //light: '#63ab9f',
      main: '#0d7c6b',
      //dark: '#63ab9f',
      contrastText: '#ffffff'
    },
    secondary: {
      //light: '#63ab9f',
      main: '#0d7c6b',
     // dark: '#63ab9f',
      contrastText: '#ffffff'
    }, /* focus colors are ffeb38 and ffe139*/
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
   
  /*typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      //'Nova Square',
      'url(/Akzidenz-grotesk-black.ttf)',
    ].join(','),
  },*/
  overrides: {
    /*MuiButton: { // override the styles of all instances of this component
      root: { // Name of the rule
        color: 'white', // Some CSS
      },
    },*/
  }
})
