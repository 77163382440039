// Login.js

import React, { useState, useEffect } from 'react'

 // react admin
import { useLogin, useNotify, Notification, useAuthState } from 'react-admin' //, Loading

// material ui
import { ThemeProvider, withStyles } from '@material-ui/styles'

// material ui components
import Paper from '@material-ui/core/Paper'
import Avatar from '@material-ui/core/Avatar'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'

// icons
import LockIcon from '@material-ui/icons/Lock'

// react router
import { useLocation } from 'react-router-dom'

const CircularProgressColor = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.contrastText
  },
}))(CircularProgress)

const CustomLogin = ({ theme }) => {

  // login state
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [requesting, setRequesting] = useState(true)

  // login stuff
  const login = useLogin()
  const notify = useNotify()
  const location = useLocation()
  // for redirecting if logged in
  const { loading, loaded, authenticated } = useAuthState()

  useEffect(() => {
    // loading auth state
    if (loading === false) {
      setRequesting(() => false)
    }
  }, [loading])

  useEffect(() => {
    // redirect if authenticated
    if (loaded === true && authenticated === true) {
      window.location.href = '/'
    }
  }, [loaded, authenticated])

  // the login function, call captcha and then send login request
  const submit = (e) => {
    e.preventDefault()
    // start loading indicator
    setRequesting(true)
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_KEY, {action: 'login'})
      .then((token) => {
        // will call authProvider.login({ username, password, token })
        login({ username, password, token }, location.state ? location.state.nextPathname : '/')
        .catch((err) => {
          setRequesting(false)
          notify('El nombre de usuario y/o la contraseña no son correctos. Por favor, vuelve a intentarlo', 'error')
        })
      })
    })
  }

  const keyPress = (e) => {
    //console.log(e)
    if (e.code === 'Enter') {
      submit(e)
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <div style={{backgroundImage: `url(/${process.env.REACT_APP_LOGIN_BACKGROUND})`, backgroundPosition: 'center', backgroundSize: 'cover'}}>
        <Paper style={{display: 'flex', marginLeft: 'auto', marginRight: 'auto', marginTop: 'auto', marginBottom: 'auto', padding: '16px', minWidth: '300px', backgroundColor: 'rgba(255, 255, 255, 0.7)'}}>
          <form onSubmit={submit} autoComplete="off" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', flexGrow: 1, marginTop: '32px', marginBottom: '32px'}}>
            <Avatar style={{width: '120px', height: '120px', backgroundColor: 'rgba(0,0,0,0)', backgroundImage: `url(/${process.env.REACT_APP_FAVICON})`, backgroundPosition: 'center', backgroundSize: 'contain'}}>
              <LockIcon style={{color: 'black'}} />
            </Avatar>
            <TextField required autoFocus id="username-input" name="username" label="Usuario" type="text" value={username} disabled={requesting} onKeyPress={keyPress} onChange={e => setUsername(e.target.value)} style={{marginTop: '16px', marginBottom: '16px', width: '90%'}} />
            <TextField required id="password-input" name="password" label="Contraseña" type="password" value={password} disabled={requesting} onKeyPress={keyPress} onChange={e => setPassword(e.target.value)} style={{marginBottom: '32px', width: '90%'}}  />
            <Button variant="contained" color="primary" type="submit" style={{width: '90%'}}>
              {requesting ? <CircularProgressColor size={24} /> : 'Iniciar Sesión'}
            </Button>
          </form>
        </Paper>
        <Notification />
      </div>
    </ThemeProvider>
  )
}

export default CustomLogin
