// Dashboard.js

import React from 'react'

// material components
import Card from '@material-ui/core/Card'
//import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'

// custom components
//import TextIcon from '../Components/TextIcon.js'

// material icons
//import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
//import BookmarkIcon from '@material-ui/icons/Bookmark'

const Dashboard = ({permissions, ...props}) => {
  //console.log(props)
  return (
  <div style={{marginTop: '24px !important'}}>
    <Card style={{textAlign: 'center'}}>
      <CardHeader title={`Bienvenido al gestor de llamadas de Galaxia`} subheader={process.env.REACT_APP_SITE_SUBTITLE} />
    </Card>
  </div>
)}

export default Dashboard
