// Menu.js

import React from 'react'

// react admin
import { Menu } from 'react-admin'

const CustomMenu = (props) =>  {
  return (
    <Menu {...props} />
  )
}

export default CustomMenu
